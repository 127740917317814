<template>
  <div class="simple-tabs">
    <component
      :is="props.tagName"
      v-for="item in items"
      class="simple-tabs__item"
      :class="{ 'is-active': item.id === value }"
      :disabled="item.disabled"
      :key="item.id"
      @click="selectTab(item)"
    >
      <atomic-image v-if="item?.icon" :src="`/img/icons/${item.icon}.svg`" alt="icon" width="24" height="24" />
      {{ item.name || item.title }}
    </component>
  </div>
</template>

<script lang="ts" setup>
  interface ITab {
    id: string | number;
    name?: string;
    title?: string;
    disabled?: boolean;
    icon?: string;
  }

  interface IProps {
    items: ITab[];
    value: string;
    tagName?: 'div' | 'span' | 'button';
  }

  const props = withDefaults(defineProps<IProps>(), {
    tagName: 'button',
  });

  const emit = defineEmits(['update:value']);

  const selectTab = (item: ITab) => {
    if (!item.disabled) {
      emit('update:value', item.id);
    }
  };
</script>

<style lang="scss" src="~/assets/styles/components/tab/simple.scss" />
